.table {
  display: table;
  margin: 0 auto;
}

.topnav {
  background-color: #333;
  overflow: hidden;
  cursor: pointer;
}

.topnav ul {
  width: 100%;
  margin: auto;
  list-style-type: none;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.topnav span {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav span:hover {
  background-color: #ddd;
  color: black;
}

.topnav span.active {
  background-color: #4CAF50;
  color: white;
}

.topNav .active {
  background-color: #4CAF50;
  color: white;
}