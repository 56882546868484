/* Created by Albert Juhé Lluveras
 https://github.com/Aljullu/react-flickr-gallery/blob/master/src/components/Lightbox/lightbox.css 
*/
.lightbox {
    background: rgba(0, 0, 0, .8);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
  
  .lightbox-credits {
    background: rgba(79, 58, 75, .8);
    bottom: 40px;
    color: black;
    font-weight: bold;
    max-width: calc(100%- 120px);
    padding: 15px;
    position: absolute;
    right: 60px;
    max-width: 30%;
    z-index: 2
  }
  
  .lightbox-description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .lightbox-img {
    cursor: zoom-out;
    left: 50%;
    max-height: 100vh;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }