.container {
    height: 100%;
    width: 100%
}

.text {
    color: whitesmoke;
    display: inline-block;
    font-family: monospace;
    font-size: 15px;
    text-align: center;
}